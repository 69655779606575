// Generated by Framer (e182fd8)

import { addFonts, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {Dape1R1ZU: {hover: true}};

const cycleOrder = ["Dape1R1ZU"];

const serializationHash = "framer-hjoPj"

const variantClassNames = {Dape1R1ZU: "framer-v-1k9vhd5"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 200, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Dape1R1ZU", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={{hash: ":JUm9oGGhs", webPageId: "augiA20Il"}} openInNewTab={false} smoothScroll><motion.a {...restProps} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1k9vhd5", className, classNames)} framer-13k4tm3`} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"Dape1R1ZU"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 30.5, borderBottomRightRadius: 30.5, borderTopLeftRadius: 30.5, borderTopRightRadius: 30.5, ...style}} variants={{"Dape1R1ZU-hover": {backgroundColor: "rgb(193, 211, 255)"}}} {...addPropertyOverrides({"Dape1R1ZU-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy1yZWd1bGFy", "--framer-font-family": "\"Poppins\", \"Poppins Placeholder\", sans-serif", "--framer-text-color": "var(--extracted-r6o4lv, rgb(50, 50, 50))"}}>Testez-Nous</motion.p></React.Fragment>} className={"framer-4e756n"} data-framer-name={"Testez-Nous"} fonts={["GF;Poppins-regular"]} layoutDependency={layoutDependency} layoutId={"N_oPYsUg7"} style={{"--extracted-r6o4lv": "rgb(50, 50, 50)", "--framer-paragraph-spacing": "0px"}} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-hjoPj.framer-13k4tm3, .framer-hjoPj .framer-13k4tm3 { display: block; }", ".framer-hjoPj.framer-1k9vhd5 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 59px; justify-content: center; padding: 0px; position: relative; text-decoration: none; width: 174px; }", ".framer-hjoPj .framer-4e756n { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-hjoPj.framer-1k9vhd5 { gap: 0px; } .framer-hjoPj.framer-1k9vhd5 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-hjoPj.framer-1k9vhd5 > :first-child { margin-left: 0px; } .framer-hjoPj.framer-1k9vhd5 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 59
 * @framerIntrinsicWidth 174
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"Xvm5O1Y3M":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerw4Vh6KoSt: React.ComponentType<Props> = withCSS(Component, css, "framer-hjoPj") as typeof Component;
export default Framerw4Vh6KoSt;

Framerw4Vh6KoSt.displayName = "testez-nous";

Framerw4Vh6KoSt.defaultProps = {height: 59, width: 174};

addFonts(Framerw4Vh6KoSt, [{explicitInter: true, fonts: [{family: "Poppins", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/poppins/v21/pxiEyp8kv8JHgFVrFJXUc1NECPY.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})